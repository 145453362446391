<template>
  <div class="d-flex align-items-center min-vh-100 login-container">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol sm="5">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <form @submit.prevent="onSubmit">
                <div class="d-block">
                  <img
                    src="@/assets/img/LogoMikroskil.png"
                    class="d-block logo-image-size"
                  />
                  <h3 class="d-block content-center">
                    Penerimaan Mahasiswa Baru dan Mahasiswa Pindahan
                  </h3>
                  <hr />
                </div>
                <div class="form-login">
                  <h2>Login</h2>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    v-model="user.username"
                    placeholder="Username"
                    autocomplete="username email"
                    required
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                    </template>
                  </CInput>
                  <CInput
                    v-model="user.password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    required
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked" />
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="4" class="text-left">
                      <CButton color="primary" class="px-4" type="submit"
                        >Login</CButton
                      >
                    </CCol>
                    <!-- <CCol col="8" class="text-right">
                      <CButton
                        color="link"
                        class="px-0"
                        @click="onClickForgetPassword"
                        >Forgot password?</CButton
                      >
                    </CCol> -->
                  </CRow>
                </div>
              </form>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      user: { username: "", password: "" },
      isFullPage: true,
      ...mapState({
        status: "authStatus",
      }),
    };
  },
  created() {
    // this.$store.dispatch("auth/clearIntervalRefresh");
  },
  methods: {
    onSubmit: async function () {
      this.$_generalMixin_setIsLoading(true);
      try {
        await this.$store.dispatch("auth/login", this.user);
        this.flash("Login Success", "success");
        this.$router.push({ name: "KonfigurasiMarketer" });

        // this.$router.push({ name: "Dashboard" });
      } catch (err) {
        this.flash("Login Failed : " + err, "error");
      }
      this.$_generalMixin_setIsLoading(false);
    },
    // onClickForgetPassword: function () {
    //   this.$router.push({ name: "ForgetPassword" });
    // },
  },
};
</script>
<style lang="scss" scoped>
.logo-image-size {
  width: 300px;
  display: block;
  margin: 0 0 0.3rem 60px;
}
.form-login {
  margin: 1rem 0.5rem;
}
.login-container {
  background: linear-gradient(to bottom, #2b32b2, #1488cc);
}
</style>
